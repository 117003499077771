import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  translationContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  tableContainer: {
    position: 'relative',
    top: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    padding: '16px 16px 16px 16px',
    borderRadius: '10px',
    //backgroundColor: '#fff',
    boxShadow: '0 11px 44px 0 rgb(18 18 19 / 10%)',
    marginTop: '24px',
  },
  btnContainer: {
    top: '60px',
    //width: '100%',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '10px',
    marginBottom: '16px',
    border: '1px solid grey',
    padding: '8px',
    borderRadius: '5px',
    backgroundColor: '#fff',
    zIndex: 10,
  },
  submit: {
    height: '40px',
    width: '40px',
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    marginBottom: 0,
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },
  dropDown: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  fileInputLabel: {
    height: '40px',
    width: '56px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: '#fff',
    background: theme.palette.primary.main,
    padding: '4px 8px',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'transform .2s ease-out',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },
  fileInput: {
    position: 'absolute',
    opacity: 0,
    display: 'none',
  },
  tableCell: {
    display: 'inline-block',
    textAlign: 'center',
  },
  cellTag: {
    display: 'inline-block',
    width: '100px',
    textAlign: 'center',
    // height: '24px'
  },
  selectMainContainer: {
    display: 'flex',
    columnGap: '8px',
  },
  selectContainer: {
    display: 'flex',
    columnGap: '8px',
    alignItems: 'center',
  },
  select: {
    padding: '8px',
    fontSize: '16px',
    borderRadius: '5px',
  },
  actionBtnContainer: {
    display: 'flex',
    columnGap: '8px',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  downloadBtn: {
    height: '40px',
    width: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: '4px 8px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },
  checkbox: {
    cursor: 'pointer',
  },
}))
